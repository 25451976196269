import type {DirectiveBinding} from "vue";

/**
 * Update binding method
 *
 */
/*const updateBinding = (first_name: string, last_name: string): string => {
    return `${first_name} ${last_name}`
}*/
const getInputValueFromHtmlElement = (el: HTMLElement):string=>{
    const inputElements = el.getElementsByTagName('ion-input')
    if(inputElements.length>0){
        return el.getElementsByTagName('ion-input')[0].value as string
    }
    return ''
}
const updateClass = (el: HTMLElement, modelValue:string)=>{
    const modelValueAsNumber = parseFloat(modelValue)
    const isOutlined = !(!isNaN(modelValueAsNumber) && (modelValueAsNumber >= 0 || modelValueAsNumber === null))
    console.log(isOutlined)
    if(isOutlined){
        el.classList.add('outlined-red')
    } else {
        el.classList.remove('outlined-red')
    }
}

export const markNegativeNumbersDirective = {

    /**
     * Created hook
     *
     * @param el - HTML element
     * @param binding - Binding
     * @return void
     */
    created(el: HTMLElement, binding: DirectiveBinding): void {
        console.log(binding)
        updateClass(el, getInputValueFromHtmlElement(el))
    },

    /**
     * Updated hook
     *
     * @param {HTMLElement} el - HTML element
     * @param {DirectiveBinding} binding - Binding
     * @return void
     */
    updated(el: HTMLElement, binding: DirectiveBinding): void {
        updateClass(el, getInputValueFromHtmlElement(el))
    }

}